import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)


  // {<a href="#" class="bg-gray-300 p-1 px-3 rounded">عربي</a>}

  return (
    <>
      <Layout>
        <Seo title="Home" />
        <div class="flex justify-between align-center items-center">
          <h1 class="mt-5">
            <StaticImage
              src="../images/logo.svg"
              class="mr-1"
              width={48}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Spacelantern logo"
              style={{ marginBottom: `1.45rem` }}
            />
            {data.site.siteMetadata?.title || `Title`}
          </h1>
        </div>
        <h2 class="font-semibold">{data.site.siteMetadata?.description || `Description`}</h2>
        

        <h2 class="mt-10">Our products:</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
            
            <div class="border-gray-200 border-4 rounded p-10">
              <div>
                <StaticImage
                  src="../images/cubtar.svg"
                  class="mr-1"
                  width={48}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Cubtar - SaaS system for auto repair shops"
                  style={{ marginBottom: `1.45rem` }}
                />
              </div>
              <h4>
                <a class="flex" href="https://cubtar.com/en/" rel="noreferrer" target="_blank">
                  Cubtar
                  <svg class="h-5 w-5 ml-1 inline" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </h4>
              <h5 class="font-normal">Auto Repair Shop Management Software</h5>
              <p class="text-sm mb-0">Smart and Enterprise-level Auto Repair Shop Management Software for Auto Shops &amp; Repair Shops. Quotes, Invoices, &amp; More.</p>
            </div>

        </div>

        <h3>We develop.</h3>
        <p class="mb-0">Our team can design &amp; develop websites, systems, and apps.<br/><br/> To get in touch, please send us an email at <a class="text-blue-700" href="mailto:hello@spacelantern.com">hello@spacelantern</a>.</p>
      </Layout>
    </>
    )
}

export default IndexPage
